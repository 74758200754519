<div
  class="border-b header dark:border-none bg-black dark:bg-[#323540] min-h-[72px] fixed top-0 w-full z-[1000] flex"
>
  <div
    class="dark:border-transparent logo logo-dark transition duration-200 ease-in-out items-center h-[71px]"
  >
    <a class="block text-center">
     
    </a>
  </div>
  <div
    class="flex flex-1 nav-wrap md:ps-[20px] md:pe-[30px] ps-[10px] pe-[15px] bg-white dark:bg-[#1b1d2a]"
  >
    <ul
      class="flex items-center mb-0 list-none nav-left ps-0 gap-x-[14px] gap-y-[9px]"
    >
      <li class="desktop-toggle">
        <a
          class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleFold()"
        >
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&.is-folded>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg"
          />
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-folded>svg]:block"
            src="assets/images/svg/align-left.svg"
          />
        </a>
      </li>
      <li class="mobile-toggle">
        <a
          class="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-transparent hover:bg-primary/10 hover:text-primary group transition duration-200 ease-in-out text-[#525768] dark:text-white/60 dark:hover:bg-white/10"
          (click)="toggleExpand()"
        >
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&.is-expand>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg"
          />
          <svg-icon
            [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }"
            class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-expand>svg]:block"
            src="assets/images/svg/align-left.svg"
          />
        </a>
      </li>
      <!-- <li>
        <a class="inline-flex items-center bg-normalBG hover:bg-primary/10 hover:text-primary dark:bg-[#282b37] dark:text-white/60 min-h-[34px] max-sm:w-[34px] max-sm:justify-center px-4 max-sm:px-0 rounded-2xl gap-[8px] dark:hover:bg-white/60 group dark:hover:text-dark transition duration-200 capitalize" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="text-sm font-medium text-body group-hover:text-primary dark:text-white/60 dark:group-hover:text-current max-sm:hidden">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->
    <app-menu *ngIf="user && user.type == 'CUSTOMER'"></app-menu>
    <!-- desktop -->
    <div class="items-center hidden ms-auto xl:flex">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="flex items-center justify-end flex-auto mb-0 list-none ps-0 nav-right sm:gap-x-[25px] max-sm:gap-x-[15px] gap-y-[15px]"
      >
       
        <li>
          <span
            class="flex items-center cursor-pointer text-light whitespace-nowrap"
            nz-dropdown
            [nzDropdownMenu]="profile"
          >
            <nz-avatar
              [nzIcon]="'user'"
              nzSrc="https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg"
            ></nz-avatar>
         
            <span
              class="[&>svg]:w-[10px] [&>svg]:h-[10px] [&>svg]:max-md:ms-[5px]"
              nz-icon
              nzType="down"
              nzTheme="outline"
            ></span>
          </span>
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div
              class="min-w-[310px] max-sm:min-w-full pt-4 px-[15px] py-[12px] bg-white dark:bg-[#1b1e2b] shadow-[0_2px_8px_rgba(0,0,0,.15)] dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4"
            >
             
        
              <a
                class="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] mx-[-15px] mb-[-15px] rounded-b-6"
                (click)="signOut()"
              >
                <i class="facebook"></i>  Logout</a
              >
            </div>
          </nz-dropdown-menu>
        </li>
      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a
      class="items-center hidden max-xl:flex text-light text-[18px] dark:text-white/[.87] ms-auto"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu"
    >
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div
        class="bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6 hidden max-xl:block"
      >
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>
