<body [dir]="'rtl'">
  <router-outlet></router-outlet>

  <ngx-spinner
    type="ball-scale-multiple"
    color="#7F00FF"
    bdColor="rgba(0, 0, 0, 0.3)"
  >
  </ngx-spinner>
</body>
