import { Routes } from '@angular/router';
import { ComponentsComponent } from '../../components/components.component'
import { AdminGuard } from '../guard/admin.guard';

export const CommonLayout_ROUTES: Routes = [

    //Dashboard
    {
     
        data: {
            title: 'routing.dashboard'
        },
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },

    //Modules
    {
      
        data: {
            title: 'SEO Section'
        },
        path: 'seo',
        loadChildren: () => import('../../modules/modules.module').then(m => m.ModulesModule),
    },

    // Charts
    {
        path: 'changelog',
        children: [
            {
                path: '',
                redirectTo: '/changelog/changelog',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangelogModule)
            },
        ]
    },

    //Apps
    {
        path: 'apps',
        data: {
            title: 'Apps'
        },
        children: [
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../apps/apps.module').then(m => m.AppsModule)
            },
        ]
    },

    //Component
    {
        path: 'demo',
        component: ComponentsComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../../components/components.module').then(m => m.ComponentsModule)
            }
        ],
        data: {
            title: 'Components '
        }
    },

    // Charts
    {
        path: 'features',
        data: {
            title: 'features'
        },
        children: [
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../features/features.module').then(m => m.FeaturesModule)
            },
        ]
    },

    //Pages
    {
        path: 'pages',
        data: {
            title: 'Pages '
        },
        children: [
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../pages/pages.module').then(m => m.PagesModule)
            },
        ]
    }
];
