import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD: SideNavInterface[] = [
  {
    path: "/dashboard",
    title: "لوحة التحكم",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },



];

export const ROUTES_ADMIN_DASHBOARD: SideNavInterface[] = [

  {
    path: "/seo",
    title: "لوحة التحكم",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },







  {
    path: "/seo/article-list",
    title: "الإشعارات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
  
  
  {
    path: "/seo/subAdmin-list",
    title: "المشرفين الفرعيين",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
  {
    path: "/seo/activityLog-SubAdmin",
    title: "سجل الأنشطة",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },

];

export const ROUTES_SUPER_ADMIN_DASHBOARD: SideNavInterface[] = [
  

  {
    path: "/seo",
    title: "لوحة التحكم",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },

  // Define super-admin routes here

  {
    path: "/seo/admin-list",
    title: "المشرفين",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
  {
    path: "/seo/topic-list",
    title: "الجهة الفرعية",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },

  {
    path: "/seo/category-list",
    title: "المحاور",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
  {
    path: "/seo/subject-list",
    title: "الجهات الرئيسية",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
  {
    path: "/seo/advertisement-list",
    title: "الإعلانات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
  {
    path: "/seo/publication-list",
    title: "المنشورات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "product",
    submenu: [],
  },
  {
    path: "/seo/activityLog-Admin",
    title: "سجل الأنشطة",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },

];

export const ROUTES_SUBADMIN_DASHBOARD: SideNavInterface[] = [
  // Define admin routes here



  {
    path: "/seo/article-list",
    title: "الإشعارات",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "article",
    submenu: [],
  },
  
  


];

