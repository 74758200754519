import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  counter = 0;
  constructor(
    public router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private spinnerService: NgxSpinnerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.counter++;
    this.spinnerService.show();

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.counter--;

          if (!this.counter) {
            this.spinnerService.hide();
          }
          if (this.counter < 0) this.counter = 0;
        }
        return event;
      }),

      catchError((error) => {
        this.counter--;
        if (!this.counter) this.spinnerService.hide();

        if (error.status == 422)
          Swal.fire({
            title: "تحقق من مدخلاتك",
            text: "يرجى التحقق من المدخلات الخاصة بك",
            icon: "warning",
          });

        if (error.status == 400)
          Swal.fire({
            title: "تحقق من مدخلاتك",
            text: "يرجى التحقق من المدخلات الخاصة بك",
            icon: "warning",
          });
        else if (error.status == 406)
          Swal.fire({
            title: this.translateService.instant(error.error.message),
            icon: "warning",
          });
        else if (error.status == 404)
          Swal.fire({
            title: this.translateService.instant(error.error.message),
            icon: "warning",
          });
        else if (error.status == 401 || error.status == 403) {
          this.counter = 0;
          this.spinnerService.hide();
          localStorage.clear();
          this.router.navigate(["/authentication/login"]);
          location.reload();
          return throwError(null);
        } else if (error.status == 500)
          this.toastr.error(
            "حدث خطأ أثناء الاتصال بالخادم، يرجى المحاولة مرة أخرى لاحقًا"
          );
        return throwError(error);
      })
    );
  }
}
