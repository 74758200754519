import { Component } from "@angular/core";

import { ThemeConstantService } from "../../services/theme-constant.service";
import {
  ROUTES_CUSTOMER_DASHBOARD, ROUTES_ADMIN_DASHBOARD,
  ROUTES_SUPER_ADMIN_DASHBOARD,
  ROUTES_SUBADMIN_DASHBOARD
} from "./side-nav-routes.config";
/*import { ROUTES_ADMIN_DASHBOARD } from "./side-nav-admin-routes";*/

@Component({
  selector: "app-sidenav",
  templateUrl: "./side-nav.component.html",
})
export class SideNavComponent {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  constructor(private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    //  this.menuItems = ROUTES.filter(menuItem => menuItem);
    if(JSON.parse(localStorage.getItem("user")).role === 'ADMIN')  
      this.menuItems = [ROUTES_ADMIN_DASHBOARD.filter((menuItem) => menuItem)]
    else if(JSON.parse(localStorage.getItem("user")).role === 'SUPERADMIN')  
    this.menuItems = [ ROUTES_SUPER_ADMIN_DASHBOARD.filter((menuItem) => menuItem)]
    else if(JSON.parse(localStorage.getItem("user")).role === 'SUBADMIN')  
      this.menuItems = [ ROUTES_SUBADMIN_DASHBOARD.filter((menuItem) => menuItem)]



    else  this.menuItems = []
    console.log(JSON.parse(localStorage.getItem("user")).role)
   



    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
  }

  ngAfterViewInit(): void {
    /* Collapsed Menu dropdown */
    let submenus = document.querySelectorAll(".ant-menu li.ant-menu-submenu");
    let direction = document.querySelector("html").getAttribute("dir");
    submenus.forEach((item) => {
      item.addEventListener("mouseover", function () {
        let menuItem = this;
        let menuItemRect = menuItem.getBoundingClientRect();
        let submenuWrapper = menuItem.querySelector("ul.ant-menu-sub");
        submenuWrapper.style.top = `${menuItemRect.top}px`;
        if (direction === "ltr") {
          submenuWrapper.style.left = `${menuItemRect.left + Math.round(menuItem.offsetWidth * 0.75) + 10
            }px`;
        } else if (direction === "rtl") {
          submenuWrapper.style.right = `${Math.round(menuItem.offsetWidth * 0.75) + 10
            }px`;
        }
      });
    });
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
}
