import { Component,Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "./shared/services/shared.service";
import { NzI18nService, ar_EG, en_US } from "ng-zorro-antd/i18n";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  direction: string;
  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private i18n: NzI18nService,
    private renderer: Renderer2
  ) {
    this.setGlobalDirection('rtl');

    localStorage.setItem("language", "ar_SA");
    translate.setDefaultLang("ar_SA");
    translate.use("ar_SA");
  }






  setGlobalDirection(direction: string) {
    this.renderer.setAttribute(document.body, 'dir', direction);
  }
}
